// eslint-disable-next-line
export default (state, action) => {
  // eslint-disable-next-line
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'SET_SMALL_LOADING':
      return {
        ...state,
        smallLoading: true,
      };

    case 'LIST_WORDS':
      return {
        ...state,
        loading: false,
        words: { ...action.payload },
      };

    case 'GET_TOTAL_PAGES':
      return {
        ...state,
        loading: false,
        totalPageNumber: action.payload,
      };
    case 'LIST_WORD':
      return {
        ...state,
        loading: false,
        word: action.payload,
      };

    case 'CREATE_WORD':
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case 'WORDS_COUNT':
      return {
        ...state,
        loading: false,
        smallLoading: false,
        wordsCount: action.payload,
      };
    case 'DELETE_WORD':
      return {
        ...state,
        smallLoading: false,
        message: action.payload,
        loading: false,
      };

    case 'SUCCESS_MESSAGE':
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case 'SEARCH_QUERY':
      return {
        ...state,
        searchTerm: action.payload,
        loading: false,
      };

    case 'LANGUAGE_CHANGE':
      return {
        ...state,
        language: action.payload,
        loading: false,
      };

    case 'SEARCH_WORDS':
      return {
        ...state,
        smallLoading: false,
        searchResult: action.payload,
      };
    case 'SELECTED_LANGUAGE':
      return {
        ...state,
        selectedLanguage: action.payload,
        loading: false,
      };
    case 'LIST_WORD_REQUEST':
      return {
        ...state,
        loading: true,
        word: null,
      };
    case 'CHANGE_PAGE':
      return {
        ...state,
        loading: true,
        pageNumber: action.payload,
      };
    case 'CREATE_WORD_FAIL':
    case 'EDIT_WORD_FAIL':
    case 'LIST_WORD_FAIL':
    case 'COUNT_WORDS_FAIL':
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
  }
};
