import React, { useState, useEffect } from "react";

import UploadSound from "../uploadSound/UploadSound";
import classes from "./InputDynamicFields.module.scss";

const InputDynamicFields = ({ addWordsFields, inputValue, category }) => {
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    setFormFields(inputValue);
  }, [inputValue]);

  const addFields = () => {
    let fields = {
      bosnian: {
        value: "",
        audio: "",
      },
      german: {
        value: "",
      },
      english: {
        value: "",
      },
    };

    if (formFields.length === 0) {
      setFormFields([fields]);
      addWordsFields([fields]);
    } else if (formFields[formFields.length - 1].bosnian.value.length > 1) {
      setFormFields([...formFields, fields]);
      addWordsFields([...formFields, fields]);
    }
  };

  const handleChangeForm = async (event, index) => {
    let data = [...formFields];

    if (!event.target.files) {
      data[index][event.target.name].value = event.target.value;
    } else {
      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          fileReader.onload = () => {
            resolve(fileReader.result);
          };

          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
      const base64 = await convertBase64(event.target.files[0]);
      data[index].bosnian.audio = base64;
    }

    setFormFields(data);
  };

  const removeFields = (index) => {
    const fields = [...formFields];
    fields.splice(index, 1);
    setFormFields(fields);
    addWordsFields(fields);
  };

  return (
    <div className={classes.fields}>
      {formFields?.map((form, index) => {
        return (
          <div key={index} style={classes.inputContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <textarea
                name="bosnian"
                placeholder="bosnian"
                onChange={(event) => handleChangeForm(event, index)}
                value={form.bosnian.value}
              />
              <UploadSound
                handleChangeForm={(event) => handleChangeForm(event, index)}
                id={index}
                form={form}
              />
            </div>

            <textarea
              name="german"
              placeholder="german"
              onChange={(event) => handleChangeForm(event, index)}
              value={form.german.value}
            />
            <textarea
              name="english"
              placeholder="english"
              onChange={(event) => handleChangeForm(event, index)}
              value={form.english.value}
            />
            {formFields.length >= 1 && (
              <div className={classes.btn} onClick={() => removeFields(index)}>
                -
              </div>
            )}

            {formFields.length - 1 === index && category !== "worterbuch" && (
              <div className={classes.btn} onClick={(e) => addFields(e)}>
                +
              </div>
            )}
          </div>
        );
      })}
      {formFields.length === 0 && (
        <>
          <div
            className={classes.addBtn}
            onClick={(e) => addFields(e)}
            style={{ display: "inline-block" }}
          >
            Hinzufügen
          </div>
        </>
      )}
    </div>
  );
};

export default InputDynamicFields;
