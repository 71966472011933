import React, { useRef } from 'react';
import AudioPlayer from 'react-modular-audio-player';

const AudioClick = ({ item }) => {
  const audio = useRef(null);
  let rearrangedPlayer = [
    {
      className: 'audio',
      innerComponents: [
        {
          type: 'play',
        },
      ],
    },
  ];

  return (
    <div
      onClick={() => audio.current && audio.current.click()}
      className={'audio-icons'}
    >
      <AudioPlayer
        audioFiles={[
          {
            src: item,
          },
        ]}
        rearrange={rearrangedPlayer}
        playerWidth='2rem'
        iconSize='23px'
      />
    </div>
  );
};

export default AudioClick;
