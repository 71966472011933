import React from 'react';
import LoginScreen from './loginScreen/LoginScreen';

const AdminScreen = () => {
  return (
    <>
      <LoginScreen />
    </>
  );
};

export default AdminScreen;
