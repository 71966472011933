import React, { useReducer, useEffect, useContext } from 'react';
import contextusReducer from '../contextus/contextusReducer';
import GlobalContext from './globalContext';
import axios from 'axios';
import authContext from '../auth/authContext';
import localhost from '../../config/config';
import { useHistory } from 'react-router-dom';

// Local storage

const selectedLanguageFromStorage = localStorage.getItem('selectedLanguage')
  ? JSON.parse(localStorage.getItem('selectedLanguage'))
  : 'DEUTSCH - B/K/S';

// Initial State

const initialState = {
  loading: false,
  smallLoading: false,
  searchResult: [],
  word: null,
  words: [],
  wordsCount: '',
  errors: '',
  message: 'Word Created',
  searchTerm: '',
  totalPageNumber: 5,
  language: 'german',
  selectedLanguage: selectedLanguageFromStorage,
  pageSizeNumber: 10,
};

// Provider Component

export const ContextusState = ({ children }) => {
  const [state, dispatch] = useReducer(contextusReducer, initialState);

  const navigate = useHistory();

  const AuthContext = useContext(authContext);

  const { user } = AuthContext;

  const { language } = state;

  const getTotalPages = async () => {
    setLoading();
    try {
      const res = await axios.get(`${localhost}/words?pageNo=3&pageSize=10`);

      dispatch({
        type: 'GET_TOTAL_PAGES',
        payload: res.data.data.totalPages,
      });
    } catch (error) {
      dispatch({
        type: 'LIST_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  useEffect(() => {
    getTotalPages();
    // eslint-disable-next-line
  }, []);

  const listWords = async (pageNum) => {
    try {
      setLoading();
      const res = await axios.get(
        `${localhost}/words?pageNo=${pageNum}&pageSize=${state.pageSizeNumber}`
      );

      dispatch({
        type: 'LIST_WORDS',
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: 'LIST_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const listWordsByVocabulary = async (pageNum, type) => {
    try {
      setLoading();
      const res = await axios.get(
        `${localhost}/words/vocabulary?pageSize=10&pageNo=${pageNum}&vocabularyType=${type}`
      );

      dispatch({
        type: 'LIST_WORDS',
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: 'LIST_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const listWordById = async (id) => {
    try {
      dispatch({ type: 'LIST_WORD_REQUEST' });
      const res = await axios.get(`${localhost}/words/${id}`);

      dispatch({
        type: 'LIST_WORD',
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: 'LIST_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const getWordsCount = async () => {
    setSmallLoading();
    try {
      const res = await axios.get(`${localhost}/words/count`);

      dispatch({
        type: 'WORDS_COUNT',
        payload: res.data.data.count,
      });
    } catch (error) {
      dispatch({
        type: 'COUNT_WORDS_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const createWord = async (data) => {
    try {
      setLoading();
      console.log(data);

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.data.token,
        },
        credentials: 'include',
      };

      const res = await axios.post(`${localhost}/words`, data, config);

      dispatch({
        type: 'SUCCESS_MESSAGE',
        payload: res.status === 200 && 'Wort hinzugefügt',
      });
    } catch (error) {
      dispatch({
        type: 'CREATE_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    navigate.push('/dashboard');
  };

  const editWord = async (id, data) => {
    try {
      setLoading();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.data.token,
        },
        credentials: 'include',
      };
      const res = await axios.put(`${localhost}/words/${id}`, data, config);

      dispatch({
        type: 'SUCCESS_MESSAGE',
        payload: res.status === 200 && 'Wort hinzugefügt',
      });
    } catch (error) {
      dispatch({
        type: 'EDIT_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    navigate.push('/dashboard');
  };

  const deleteWord = async (id) => {
    try {
      setSmallLoading();
      const config = {
        headers: {
          Authorization: user.data.token,
        },
      };

      const res = await axios.delete(`${localhost}/words/${id}`, config);
      console.log(res);

      dispatch({
        type: 'DELETE_WORD',
        payload: res.data.data,
      });

      listWords(state.words.totalPages);
    } catch (error) {
      dispatch({
        type: 'DELETE_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const searchWords = async (searchTerm, limit = 50) => {
    try {
      dispatch({
        type: 'SET_SMALL_LOADING',
      });
      const url = `${localhost}/words/search?limit=${limit}&lan=${language}&word=${searchTerm}`;
      const res = await axios.get(url);

      dispatch({
        type: 'SEARCH_WORDS',
        payload: res.data.data,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const filterWords = async (searchTerm) => {
    try {
      setLoading();
      const url = `${localhost}/words/search?limit=50&lan=${language}&word=${searchTerm}`;
      const res = await axios.get(url);

      dispatch({
        type: 'LIST_WORDS',
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: 'LIST_WORD_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const setLoading = () => dispatch({ type: 'SET_LOADING' });
  const setSmallLoading = () => dispatch({ type: 'SET_SMALL_LOADING' });
  return (
    <GlobalContext.Provider
      value={{
        editWord,
        createWord,
        deleteWord,
        listWords,
        getWordsCount,
        setLoading,
        listWordById,
        dispatch,
        searchWords,
        getTotalPages,
        setSmallLoading,
        filterWords,
        listWordsByVocabulary,
        words: state.words,
        word: state.word,
        wordsCount: state.wordsCount,
        errors: state.errors,
        message: state.message,
        loading: state.loading,
        searchTerm: state.searchTerm,
        language: state.language,
        searchResult: state.searchResult,
        selectedLanguage: state.selectedLanguage,
        totalPageNumber: state.totalPageNumber,
        smallLoading: state.smallLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
