import React from 'react';
import classes from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={`${classes.errorPage}`}>
      <h2>404</h2>
      <p>nicht gefunden!</p>
    </div>
  );
};

export default NotFound;
