import React, { useContext } from 'react';
import classes from './Navbar.module.scss';
import authContext from '../../context/auth/authContext';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../logo/Logo';

const Navbar = () => {
  const AuthContext = useContext(authContext);
  const { user, logout } = AuthContext;

  const history = useHistory();
  const location = useLocation();

  const onLogoutHandeler = () => {
    logout();
    history.push('/');
  };

  const flexCenter = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <div className={classes.container}>
      <Logo />

      {location.pathname !== '/login' && (
        <div className={classes.user}>
          {user && (
            <>
              <Link to='/' style={flexCenter}>
                <i className='fas fa-home-alt'></i>
                Home
              </Link>

              <Link to='/dashboard' style={flexCenter}>
                <i className='fas fa-chart-line'></i>
                Dashboard
              </Link>
            </>
          )}
          {user ? (
            <div
              style={flexCenter}
              onClick={onLogoutHandeler}
              className={classes.onHover}
            >
              <i className='fas fa-user'></i>
              Logout
            </div>
          ) : (
            <div
              style={flexCenter}
              className={classes.onHover}
              onClick={() => history.push('/login')}
            >
              <i className='fas fa-user' style={{ marginLeft: '5px' }}></i>
              <span>Login</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
