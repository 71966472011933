import React, { useContext } from 'react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import classes from './LoginScreen.module.scss';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alerts from '../../components/alerts/Alerts';
import Spinner from '../../components/spinner/Spinner';

// Validation with yup

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Bitte geben Sie ihre E-Mail-Adresse ein'),
  password: yup.string().required('Bitte geben Sie Ihr Passwort ein'),
});

const LoginScreen = () => {
  const history = useHistory();
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { login, isAuthenticated, user, error, clearErrors, loading } =
    authContext;

  useEffect(() => {
    if (user !== null && isAuthenticated) {
      history.push('/dashboard');
    }
    if (error !== null) {
      setAlert(error, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [user, isAuthenticated, error]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    login(data);
  };

  const style = {
    color: '#990000',
    fontSize: '12px',
    marginBottom: '20px',
    marginLeft: '10px',
  };

  return (
    <div className={classes.login}>
      <h2>Login</h2>
      <p style={{ textAlign: 'center', margin: '1rem 0px' }}>Nur für admin</p>
      {loading && <Spinner size={'150px'} />}
      <form
        className={classes.form}
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <Alerts />
        <input
          {...register('email', { required: errors.email?.message })}
          placeholder='email'
          type='email'
        />
        <p style={style}>{errors.email?.message}</p>

        <input
          {...register('password', { required: errors.email?.message })}
          placeholder='password'
          type='password'
        />
        <p style={style}>{errors.password?.message}</p>

        <div className={classes.btnContainer}>
          <button type='submit'>Sign In</button>
        </div>
      </form>
    </div>
  );
};

export default LoginScreen;
