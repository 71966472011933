import React, { useContext } from 'react';
import classes from './Modal.module.scss';
import globalContext from '../../context/contextus/globalContext';
import Spinner from '../spinner/Spinner';

const Modal = ({ setModal, modal }) => {
  const GlobalContext = useContext(globalContext);

  const { deleteWord, smallLoading } = GlobalContext;

  const deleteWordHandeler = () => {
    deleteWord(modal.id);
    if (!smallLoading) {
      setTimeout(() => {
        setModal({
          condition: false,
          id: null,
        });
      }, 300);
    }
  };

  return (
    <div className={classes.overlay}>
      <div className={classes.modal}>
        <span className={classes.sign}>!</span>
        <h3 className={classes.heading}>Bist du dir sicher</h3>
        <p className={classes.text}>Sie können dies nicht rückgängig machen</p>
        {smallLoading ? (
          <Spinner />
        ) : (
          <div style={{ marginTop: ' 20px' }}>
            <button className={classes.btn} onClick={deleteWordHandeler}>
              Ja
            </button>
            <button
              className={classes.btn}
              onClick={() =>
                setModal({
                  condition: false,
                  id: null,
                })
              }
            >
              Nein
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
