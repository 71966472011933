import React, { useContext } from 'react';
import globalContext from '../../context/contextus/globalContext';
import classes from './Table.module.scss';
import AudioClick from '../audioClick/AudioClick';

const Table = ({ category, language, word }) => {
  const GlobalContext = useContext(globalContext);

  const { selectedLanguage } = GlobalContext;

  const firstLangTitle = selectedLanguage.split(' -')[0];

  const secondLangTitle = selectedLanguage.split(' -')[1];

  const correctCategory =
    category === 'wörterbuch'
      ? 'worterbuch'
      : category === 'fremdwörterbuch'
      ? 'fremdworterbuch'
      : category;

  return (
    <div className={classes.table}>
      <table>
        <thead>
          <tr>
            <th>{firstLangTitle.toUpperCase()}</th>
            <th>{secondLangTitle.toUpperCase()}</th>
          </tr>
        </thead>
        <tbody>
          {word[correctCategory]?.map((item) => (
            <tr key={item._id}>
              <td data-column={firstLangTitle}>
                <div>
                  <p style={{ whiteSpace: 'pre-wrap', marginBottom: '5px' }}>
                    {item[language].value}{' '}
                  </p>

                  {selectedLanguage === 'B/K/S - DEUTSCH' &&
                    item.bosnian.audio &&
                    item.bosnian.audio.value !== '' && (
                      <AudioClick item={item.bosnian.audio} />
                    )}
                  {selectedLanguage === 'B/K/S - ENGLISCH' &&
                    item.bosnian.audio &&
                    item.bosnian.audio.value !== '' && (
                      <AudioClick item={item.bosnian.audio} />
                    )}
                </div>
              </td>
              <td data-column={secondLangTitle}>
                {selectedLanguage === 'DEUTSCH - B/K/S' && (
                  <div>
                    <p style={{ whiteSpace: 'pre-wrap', marginBottom: '5px' }}>
                      {item.bosnian.value}{' '}
                    </p>

                    {item.bosnian.audio && item.bosnian.audio.value !== '' && (
                      <AudioClick item={item.bosnian.audio} />
                    )}
                  </div>
                )}
                {selectedLanguage === 'B/K/S - DEUTSCH' && item.german.value}
                {selectedLanguage === 'B/K/S - ENGLISCH' && item.english.value}
                {selectedLanguage === 'ENGLISCH - B/K/S' && (
                  <div>
                    <p style={{ whiteSpace: 'pre-wrap', marginBottom: '5px' }}>
                      {item.bosnian.value}{' '}
                    </p>
                    {item.bosnian.audio && item.bosnian.audio.value !== '' && (
                      <AudioClick item={item.bosnian.audio} />
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
