import React, { useContext, useState, useEffect } from 'react';
import classes from './WordsResults.module.scss';
import globalContext from '../../context/contextus/globalContext';
import { useHistory } from 'react-router-dom';
import Spinner from '../spinner/Spinner';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';

const WordsResults = ({ changeLanguageName }) => {
  const {
    searchResult,
    language,
    selectedLanguage,
    smallLoading,
    listWordById,
  } = useContext(globalContext);

  const history = useHistory();
  const [showMore, setShowMore] = useState(2);

  useEffect(() => {
    setShowMore(2);
  }, [searchResult]);

  const onClickShowMore = (e, num) => {
    e.stopPropagation();
    setShowMore(showMore === 2 ? num : 2);
  };

  const onClickFilterData = (result, category) => {
    listWordById(result._id);
    const word = result.word[language].value.toLowerCase();
    const checkSeparator = word.includes('/')
      ? word.lastIndexOf('/')
      : word.lastIndexOf('');

    history.push(
      `${changeLanguageName}/${category.replace(/\s/g, '+')}/${word
        .replace(/\s/g, '-')
        .slice(0, checkSeparator)}`
    );
  };

  const renderPhrases = (result) => {
    const phrasen = result.phrasen;
    const isPhrasesAvailable =
      phrasen.length > 0 && phrasen[0]?.[language]?.value !== '';

    return (
      isPhrasesAvailable && (
        <div
          className={classes.item}
          onClick={() => onClickFilterData(result, 'phrasen')}
        >
          <h3>Phrasen u. Anwendungsbeispiele</h3>
          {phrasen.slice(0, showMore).map((phrasenItem, index) => (
            <p key={index * 2}>{phrasenItem[language].value}</p>
          ))}
          {phrasen.length > 2 && showMore === 2 && '...'}
          {phrasen.length > 2 && (
            <div
              className={classes.showMore}
              onClick={(e) => onClickShowMore(e, phrasen.length)}
            >
              {showMore === 2 ? <BsArrowDownCircle /> : <BsArrowUpCircle />}
            </div>
          )}
        </div>
      )
    );
  };

  const renderDictionary = (result, type) => {
    const dictionary = result[type];
    const isDictionaryAvailable =
      dictionary.length > 0 && dictionary[0]?.german.value !== '';

    return (
      isDictionaryAvailable && (
        <div
          className={classes.item}
          onClick={() => onClickFilterData(result, type)}
        >
          <h3>{type === 'worterbuch' ? 'Wörterbuch' : 'Fremdwörterbuch'}</h3>
          {dictionary.slice(0, showMore).map((item, index) => (
            <p
              key={index * 2}
              style={{ whiteSpace: 'pre-wrap', marginBottom: '5px' }}
            >
              {item[language].value}
            </p>
          ))}
          {dictionary.length > 2 && showMore === 2 && '...'}
          {dictionary.length > 2 && (
            <div
              className={classes.showMore}
              onClick={(e) => onClickShowMore(e, dictionary.length)}
            >
              {showMore === 2 ? <BsArrowDownCircle /> : <BsArrowUpCircle />}
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <div className={classes.results}>
      {smallLoading ? (
        <Spinner />
      ) : (
        <>
          {searchResult.length === 0 && <p>Kann kein Wort finden</p>}
          {searchResult &&
            searchResult.map((result) => (
              <div className={classes.items} key={result._id}>
                <div>
                  <div className={classes.items}>
                    {result.worterbuch?.length === 0 &&
                      result.fremdworterbuch?.length === 0 && (
                        <p>Kann kein Wort finden</p>
                      )}
                    {language === 'german' ? (
                      <div className={classes.heading}>
                        <h4>{result.word?.german.value} - </h4>
                        {'  '}
                        <h4>
                          {selectedLanguage === 'DEUTSCH - B/K/S'
                            ? result.word.bosnian.value
                            : result.word.english.value}
                        </h4>
                      </div>
                    ) : (
                      <div className={classes.heading}>
                        <h4>{result.word[language].value} - </h4>{' '}
                        {selectedLanguage === 'B/K/S - ENGLISCH' ? (
                          <h4>{result.word.english.value}</h4>
                        ) : (
                          <h4>{result.word.bosnian.value}</h4>
                        )}
                      </div>
                    )}
                  </div>
                  {renderDictionary(result, 'worterbuch')}
                  {renderPhrases(result)}
                  {renderDictionary(result, 'fremdworterbuch')}
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default WordsResults;
