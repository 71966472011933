import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classes from './DashBoard.module.scss';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import Spinner from '../../components/spinner/Spinner';
import Modal from '../../components/modal/Modal';
import ReactDom from 'react-dom';
import globalContext from '../../context/contextus/globalContext';
import WordList from '../../components/WordList';
import { AiFillFileWord, AiOutlineFileWord } from 'react-icons/ai';
import { BsArrowLeftSquare, BsArrowRightSquare } from 'react-icons/bs';
import { createMultipleWordDoc } from '../../helper/createMultipleWordDoc';

// Validation with yup

const DashBoard = () => {
  const GlobalContext = useContext(globalContext);

  const {
    listWords,
    words,
    loading,
    totalPageNumber,
    filterWords,
    listWordsByVocabulary,
    getTotalPages,
  } = GlobalContext;

  const [modal, setModal] = useState({
    condition: false,
    id: '',
  });

  const [currentPage, setCurrentPage] = useState(totalPageNumber);

  const [searchValue, setSearchValue] = useState('');

  const [selects, setSelects] = useState('All');

  const [page, setPage] = useState();

  const inputRef = useRef();

  const listDataBySelect = (currentPage, selects) => {
    if (selects === 'All') {
      listWords(currentPage);
    } else {
      listWordsByVocabulary(currentPage, selects);
      setCurrentPage(1);
    }
  };

  const debouncedFilter = useCallback(
    debounce(() => {
      if (inputRef.current.value === searchValue) {
        filterWords(searchValue);
      }
    }, 500),
    [searchValue]
  );

  useEffect(() => {
    if (searchValue.length > 3) {
      debouncedFilter();
    } else if (searchValue === '') {
      listDataBySelect(currentPage, selects);
    }
    // eslint-disable-next-line
  }, [searchValue, debouncedFilter, selects]);

  const currentData = useCallback(() => {
    setCurrentPage(totalPageNumber);

    // eslint-disable-next-line
  }, [totalPageNumber, selects]);

  useEffect(() => {
    currentData();
    getTotalPages();

    // eslint-disable-next-line
  }, [totalPageNumber]);

  useEffect(() => {
    setCurrentPage(totalPageNumber);
  }, [totalPageNumber]);

  useEffect(() => {
    listDataBySelect(currentPage, selects);
    setCurrentPage(currentPage);

    // eslint-disable-next-line
  }, [currentPage]);

  const pages = new Array(totalPageNumber).fill(null).map((v, i) => i);

  const history = useHistory();

  const onClickChangePages = (pageNum) => {
    /* setPage(pageNum + 1); */
    setCurrentPage(pageNum + 1);
    setPage(pageNum);
  };

  const gotoPrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage(Math.max(0, currentPage - 1));
    }
  };

  const gotoNext = () => {
    if (currentPage !== pages.length) {
      setCurrentPage(Math.min(currentPage + 1));
    }
  };

  const onCLickCreateWordDocument = (data) => {
    createMultipleWordDoc(data, selects);
  };

  return (
    <div className={classes.dashboard}>
      <h2>Wörter hinzugefügt</h2>
      <h3>{words && 'Page: ' + currentPage}</h3>
      <h3>Wörter filtern</h3>
      <select
        className={classes.select}
        value={selects}
        onChange={(e) => setSelects(e.target.value)}
      >
        <option>All</option>
        <option>A1-A2</option>
        <option>B1-C2</option>
      </select>
      <div className={`container ${classes.filter}`}>
        <input
          className={classes['form-control']}
          placeholder={`Suchwörter`}
          type='text'
          value={searchValue}
          ref={inputRef}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {selects !== 'All' && (
            <div className='container'>
              <span
                className={classes.customWord}
                onClick={() => onCLickCreateWordDocument(words.data, selects)}
              >
                <AiOutlineFileWord className={classes.word} />
                <AiFillFileWord className={classes.word} />
                <AiOutlineFileWord className={classes.word} />
              </span>
            </div>
          )}
          <div className={`container ${classes.words}`}>
            {!loading &&
              words.data?.map((word, index) => (
                <WordList item={word} key={index} setModal={setModal} />
              ))}

            {words.data?.length <= 10 && (
              <div>
                <i
                  className='fa-solid fa-plus fa-2x'
                  style={{ color: 'green', cursor: 'pointer', padding: '10px' }}
                  onClick={() => history.push('/create')}
                ></i>
              </div>
            )}
          </div>

          <div
            className={`container ${classes.pagination}`}
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {currentPage !== 1 && (
              <BsArrowLeftSquare
                onClick={gotoPrevious}
                style={{ marginRight: '9px' }}
              />
            )}
            {pages.map((pageNum, index) => (
              <button
                style={
                  currentPage === pageNum + 1 ? { background: '#000' } : null
                }
                key={index}
                className={classes[`btn-pages`]}
                onClick={() => onClickChangePages(pageNum)}
              >
                {pageNum + 1}
              </button>
            ))}
            {currentPage !== pages.length && (
              <BsArrowRightSquare onClick={gotoNext} />
            )}
          </div>
        </>
      )}

      {modal.condition &&
        ReactDom.createPortal(
          <Modal modal={modal} setModal={setModal} />,
          document.getElementById('modal-root')
        )}
    </div>
  );
};

export default DashBoard;
