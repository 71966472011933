import React, { useEffect, useState } from 'react';
import Search from '../../components/search/Search';
import classes from './MainScreen.module.scss';
import de_bos from '../../assets/images/de-bos.png';
import bos_de from '../../assets/images/bos-de.png';
import bos_uk from '../../assets/images/bos-uk.png';
import uk_bos from '../../assets/images/uk-bos.png';
import { useContext } from 'react';
import globalContext from '../../context/contextus/globalContext';
import Spinner from '../../components/spinner/Spinner';
import WordsResults from '../../components/wordsResults/WordsResults';
import SelectLanguage from '../../components/selectLanguage/SelectLanguage';

const MainScreen = () => {
  const GlobalContext = useContext(globalContext);

  const [changeLanguageName, setChangeLanguageName] = useState('');

  const {
    wordsCount,
    getWordsCount,
    loading,
    dispatch,
    searchResult,
    selectedLanguage,
  } = GlobalContext;

  let limit;

  useEffect(() => {
    getWordsCount();
    if (selectedLanguage === 'B/K/S - DEUTSCH' || 'B/K/S - ENGLISCH') {
      dispatch({
        type: 'LANGUAGE_CHANGE',
        payload: 'bosnian',
      });
    }
    if (selectedLanguage === 'DEUTSCH - B/K/S') {
      dispatch({
        type: 'LANGUAGE_CHANGE',
        payload: 'german',
      });
    }
    if (selectedLanguage === 'ENGLISCH - B/K/S') {
      dispatch({
        type: 'LANGUAGE_CHANGE',
        payload: 'english',
      });
    }
    const removeLetters = selectedLanguage
      .split('/')
      .join('')
      .replace(/\s/g, '');
    setChangeLanguageName(removeLetters.toLowerCase());

    // eslint-disable-next-line
  }, [wordsCount, selectedLanguage, dispatch]);

  return (
    <div className={classes.main}>
      {loading ? (
        <Spinner />
      ) : (
        <div className='container'>
          <p style={{ fontSize: '20px' }}>
            Deutsch - Bosnisch / Kroatisch / Serbisch
          </p>
          <p style={{ marginTop: '10px' }}>Übersetzungen:</p>
          <span>{wordsCount}</span>

          <div className={classes.category}>
            <SelectLanguage />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={classes.images}>
                <img
                  src={
                    (selectedLanguage === 'DEUTSCH - B/K/S' && de_bos) ||
                    (selectedLanguage === 'B/K/S - DEUTSCH' && bos_de) ||
                    (selectedLanguage === 'B/K/S - ENGLISCH' && bos_uk) ||
                    (selectedLanguage === 'ENGLISCH - B/K/S' && uk_bos)
                  }
                  height='30px'
                  alt='languages'
                />
              </div>
            </div>
            <h3 style={{ marginTop: '50px' }}>Suche ein Wort </h3>
            <Search limit={limit} />
            {searchResult && (
              <WordsResults
                limit={limit}
                changeLanguageName={changeLanguageName}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainScreen;
