import { SET_ALERT, REMOVE_ALERT } from '../types';

const alertReducer = (state, action) => {
  /* const checkMessage =
    action.payload.msg === 'Alert! Wrong Credentials.'
      ? 'Warnung!Falsche Anmeldeinformationen.'
      : action.payload.msg;

  const newPayload = {
    ...action.payload,
    msg: checkMessage,
  }; */
  switch (action.type) {
    case SET_ALERT:
      return [...state, action.payload];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== action.payload);
    default:
      return state;
  }
};

export default alertReducer;
