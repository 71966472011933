import React, { useContext, useState, useEffect } from 'react';
import globalContext from '../../context/contextus/globalContext';

const SelectLanguage = () => {
  const GlobalContext = useContext(globalContext);

  const { dispatch, selectedLanguage } = GlobalContext;

  const [selects, setSelects] = useState(selectedLanguage);

  const onChangeHandeler = (e) => {
    dispatch({ type: 'SEARCH_QUERY', payload: '' });
    setSelects(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: 'SEARCH_WORDS',
      payload: null,
    });
    dispatch({
      type: 'SELECTED_LANGUAGE',
      payload: selects,
    });
  }, [selects, dispatch]);

  return (
    <select value={selects} onChange={onChangeHandeler}>
      <option> DEUTSCH - B/K/S</option>
      <option> B/K/S - DEUTSCH </option>
      <option> B/K/S - ENGLISCH </option>
      <option> ENGLISCH - B/K/S </option>
    </select>
  );
};

export default SelectLanguage;
