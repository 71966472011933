import './App.scss';
import AdminScreen from './screen/AdminScreen';
import MainScreen from './screen/mainScreen/MainScreen';
import DashBoard from './screen/dashboard/DashBoard';
import { Switch, Route } from 'react-router-dom';
import CreateScreen from './screen/createScreen/CreateScreen';
import EditScreen from './screen/editScreen/EditScreen';
import AuthState from './context/auth/AuthState';
import PrivateRoute from './auth/PrivateRoute';
import Navbar from './components/menu/Navbar';
import AlertState from './context/alert/AlertState';
import { ContextusState } from './context/contextus/ContextusState';
import SearchResultScreen from './screen/searchResultScreen/SearchResultScreen';
import SearchScreenList from './screen/searchScreenList/SearchScreenList';
import NotFound from './components/notFound/NotFound';

const App = () => {
  return (
    <AuthState>
      <AlertState>
        <ContextusState>
          <div className='App'>
            <Navbar />
            <Switch>
              <Route exact path='/' component={MainScreen} />
              <Route exact path='/login' component={AdminScreen} />
              <Route exact path='/results' component={SearchScreenList} />

              <Route
                exact
                path='/:language/:category/:word'
                component={SearchResultScreen}
              />

              <PrivateRoute path='/dashboard' component={DashBoard} />
              <PrivateRoute path='/create' component={CreateScreen} />
              <PrivateRoute path='/edit/:id' component={EditScreen} />
              <Route path='*' component={NotFound} />
            </Switch>
          </div>
        </ContextusState>
      </AlertState>
    </AuthState>
  );
};

export default App;
