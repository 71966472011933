import React, { useContext } from 'react';
import { useEffect } from 'react';
import WordsResults from '../../components/wordsResults/WordsResults';
import globalContext from '../../context/contextus/globalContext';
import { useLocation } from 'react-router-dom';
const SearchScreenList = () => {
  const GlobalContext = useContext(globalContext);

  const { searchResult, searchWords } = GlobalContext;

  const location = useLocation();

  const term = location.search.replace('?', '');

  useEffect(() => {
    searchWords(term);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginTop: '20px', paddingBottom: '40px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
        Suchergebnis
      </h2>

      {searchResult ? (
        <WordsResults />
      ) : (
        <p style={{ textAlign: 'center', marginTop: '20px' }}>
          {' '}
          "Kein Suchergebnis"
        </p>
      )}
    </div>
  );
};

export default SearchScreenList;
