import React, { useContext } from 'react';
import classes from './Logo.module.scss';
import authContext from '../../context/auth/authContext';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const Logo = () => {
  const AuthContext = useContext(authContext);
  const { user } = AuthContext;

  const name = user ? user.data.user.name : null;

  return (
    <>
      <p className={classes.admin}>{user && name}</p>
      <div>
        <Link to='/'>
          <img src={logo} className={classes.logo} alt='logo' />
        </Link>
      </div>
    </>
  );
};

export default Logo;
