import React, { useState, useContext } from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./CreateScreen.module.scss";
import globalContext from "../../context/contextus/globalContext";
import InputDynamicFields from "../../components/inputDynamicFields/InputDynamicFields";
import Spinner from "../../components/spinner/Spinner";

// Validation with yup

const schema = yup.object().shape({
  word: yup.object().shape({
    german: yup.object().shape({
      value: yup.string().required("Deutsch Sprache ist erforderlich"),
    }),
  }),
});

const CreateScreen = () => {
  const GlobalContext = useContext(globalContext);

  const { createWord, loading } = GlobalContext;

  const [selectVocabularyType, setSelectVocabularyType] = useState("A1-A2");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [fremdworterbuch, setFremworterbuch] = useState([]);
  const [phrasen, setPhrasen] = useState([]);
  const [worterBuch, setWorterbuch] = useState([
    {
      bosnian: {
        value: "",
        audio: "",
      },
      german: {
        value: "",
      },
      english: {
        value: "",
      },
    },
  ]);

  const onSubmit = (data) => {
    const { word } = data;

    let objectData = {
      vocabularyType: selectVocabularyType,
      worterbuch: worterBuch,
      phrasen,
      fremdworterbuch,
      word,
    };

    createWord(objectData);
  };

  const style = {
    color: "#990000",
    fontSize: "12px",
    marginBottom: "20px",
    marginLeft: "10px",
  };
  return (
    <div className={classes.login}>
      <h2>Wörter hinzufügen</h2>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container">
          <select
            value={selectVocabularyType}
            onChange={(e) => setSelectVocabularyType(e.target.value)}
            className={classes.selectedLanguage}
          >
            <option> A1-A2</option>
            <option> B1-C2 </option>
          </select>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="">Bosnisch</label>
              <input
                {...register("word.bosnian.value")}
                placeholder="Bosnisch"
                type="text"
              />
            </div>
            <div>
              <label htmlFor="">Deutsch</label>
              <input
                {...register("word.german.value")}
                placeholder="Deutsch"
                type="text"
              />
              <p style={style}>{errors.word?.german.value?.message}</p>
            </div>
            <div>
              <label htmlFor="">Englisch</label>
              <input
                {...register("word.english.value")}
                placeholder="Englisch"
                type="text"
              />
            </div>

            <h2>Wörterbuch</h2>
            <div
              style={{
                gridColumn: "1 / span 3",
              }}
            >
              <InputDynamicFields
                addWordsFields={setWorterbuch}
                inputValue={worterBuch}
                category={"worterbuch"}
              />
            </div>

            <h2>Phrasen</h2>
            <div
              style={{
                gridColumn: "1 / span 3",
              }}
            >
              <InputDynamicFields
                addWordsFields={setPhrasen}
                inputValue={phrasen}
                category={"phrasen"}
              />
            </div>
            <h2>Fremdwörterbuch</h2>
            <div
              style={{
                gridColumn: "1 / span 3",
              }}
            >
              <InputDynamicFields
                addWordsFields={setFremworterbuch}
                inputValue={fremdworterbuch}
                category={"fremdworterbuch"}
              />
            </div>
            <div className={classes.btnContainer}>
              <button type="submit">Einreichen</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CreateScreen;
