import React, { useContext } from 'react';
import globalContext from '../../context/contextus/globalContext';
import de_bos from '../../assets/images/de-bos.png';
import bos_de from '../../assets/images/bos-de.png';
import bos_uk from '../../assets/images/bos-uk.png';
import uk_bos from '../../assets/images/uk-bos.png';
import { useParams } from 'react-router-dom';
import classes from './SearchResultScreen.module.scss';
import Spinner from '../../components/spinner/Spinner';
import Table from '../../components/table/Table';

const SearchResultScreen = () => {
  const GlobalContext = useContext(globalContext);
  const { word, language, selectedLanguage, loading } = GlobalContext;

  const params = useParams();

  const { category } = params;

  /* useEffect(() => {
    listWordById(word._id);
    // eslint-disable-next-line
  }, [word]); */

  return (
    <div className={classes.translations}>
      {word && !loading ? (
        <>
          <div className={classes.headings}>
            <h2 style={{ textTransform: 'uppercase' }}>
              {category === 'wörterbuch' && 'Wörterbuch'}
              {category === 'phrasen' && 'Phrasen u. Anwendungsbeispiele'}
              {category === 'fremdwörterbuch' && 'Fremdwörterbuch'}
            </h2>
          </div>

          <div className='container text-center'>
            <p
              style={{
                textAlign: 'center',
                padding: '20px',
                fontSize: '19px',
                color: '#00a69c',
              }}
            >
              <img
                src={
                  (selectedLanguage === 'DEUTSCH - B/K/S' && de_bos) ||
                  (selectedLanguage === 'B/K/S - DEUTSCH' && bos_de) ||
                  (selectedLanguage === 'B/K/S - ENGLISCH' && bos_uk) ||
                  (selectedLanguage === 'ENGLISCH - B/K/S' && uk_bos)
                }
                width='140px'
                height='auto'
                alt='languages'
              />
            </p>
            <div className={classes.translation}>
              <Table word={word} language={language} category={category} />
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SearchResultScreen;
