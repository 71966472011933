import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classes from './EditScreen.module.scss';
import globalContext from '../../context/contextus/globalContext';
import InputDynamicFields from '../../components/inputDynamicFields/InputDynamicFields';
import Spinner from '../../components/spinner/Spinner';

// Validation with yup

const schema = yup.object().shape({
  word: yup.object().shape({
    german: yup.object().shape({
      value: yup.string().required('Deutsche Sprache ist erforderlich'),
    }),
  }),
});

const EditScreen = () => {
  const params = useParams();
  const GlobalContext = useContext(globalContext);

  const { word, loading, editWord } = GlobalContext;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectVocabularyType, setSelectVocabularyType] = useState('');

  const [worterBuch, setWorterbuch] = useState([]);

  const [fremdworterbuch, setFremworterbuch] = useState([]);
  const [phrasen, setPhrasen] = useState([]);

  useEffect(() => {
    if (word) {
      setFremworterbuch([...word.fremdworterbuch]);
      setPhrasen([...word.phrasen]);
      setWorterbuch([...word.worterbuch]);
    }
    if (word && word.vocabularyType) {
      setSelectVocabularyType(word.vocabularyType);
    } else {
      setSelectVocabularyType('A1-A2');
    }
  }, [word, params.id]);

  const onSubmit = (data) => {
    const { word } = data;

    let objectData = {
      vocabularyType: selectVocabularyType,
      worterbuch: worterBuch,
      phrasen,
      fremdworterbuch,
      word,
    };

    editWord(params.id, objectData);
  };

  const style = {
    color: '#990000',
    fontSize: '12px',
    marginBottom: '20px',
    marginLeft: '10px',
  };

  return (
    <div className={classes.login}>
      <h2>Wort bearbeiten</h2>

      {loading ? (
        <Spinner />
      ) : (
        <div className='container'>
          {word ? (
            <>
              {' '}
              <select
                value={selectVocabularyType}
                onChange={(e) => setSelectVocabularyType(e.target.value)}
                className={classes.selectedLanguage}
              >
                <option> A1-A2</option>
                <option> B1-C2 </option>
              </select>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor=''>Bosnisch</label>
                  <input
                    {...register('word.bosnian.value')}
                    defaultValue={word && word.word?.bosnian.value}
                    placeholder='Bosnisch'
                    type='text'
                  />

                  {/*  <p style={style}>{errors.word?.bosnian.value?.message}</p> */}
                </div>

                <div>
                  <label htmlFor=''>Deutsch</label>
                  <input
                    {...register('word.german.value')}
                    defaultValue={word && word.word?.german.value}
                    placeholder='Deutsch'
                    type='text'
                  />
                  <p style={style}>{errors.word?.german.value?.message}</p>
                </div>

                <div>
                  <label htmlFor=''>Englisch</label>
                  <input
                    {...register('word.english.value')}
                    defaultValue={word && word.word?.english.value}
                    placeholder='Englisch'
                    type='text'
                  />
                </div>
                <h2>Wörterbuch</h2>
                <div
                  style={{
                    gridColumn: '1 / span 3',
                  }}
                >
                  <InputDynamicFields
                    addWordsFields={setWorterbuch}
                    inputValue={worterBuch}
                    category={'worterbuch'}
                  />
                </div>
                <h2>Phrasen</h2>
                <div
                  style={{
                    gridColumn: '1 / span 3',
                  }}
                >
                  <InputDynamicFields
                    addWordsFields={setPhrasen}
                    inputValue={phrasen}
                    category={'phrasen'}
                  />
                </div>
                <h2>Fremdwörterbuch</h2>
                <div
                  style={{
                    gridColumn: '1 / span 3',
                  }}
                >
                  <InputDynamicFields
                    addWordsFields={setFremworterbuch}
                    inputValue={fremdworterbuch}
                    category={'fremdworterbuch'}
                  />
                </div>
                <div className={classes.btnContainer}>
                  <button type='submit'>Einreichen</button>
                </div>
              </form>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      )}
    </div>
  );
};

export default EditScreen;
