import { saveAs } from 'file-saver';
import {
  Packer,
  Paragraph,
  Document,
  AlignmentType,
  HeadingLevel,
  TableRow,
  TableCell,
  Table,
  WidthType,
} from 'docx';

export function createSingleWordDoc(item) {
  const { word, worterbuch, phrasen, fremdworterbuch, vocabularyType } = item;
  console.log(item.length);

  const createHeading = (text) => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
    });
  };

  const createParagraph = (text) => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
    });
  };

  const generateDocWord = () => {
    const table = new Table({
      width: {
        size: 9000,
        type: WidthType.DXA,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [createParagraph(word.bosnian.value)],
            }),
            new TableCell({
              children: [createParagraph(word.german.value)],
            }),
          ],
        }),
      ],
    });

    const createTableData = (wordData) => {
      if (wordData.length > 0) {
        wordData.forEach((data) => {
          let tableRow = new TableRow({
            children: [
              new TableCell({
                children: [createParagraph(data.bosnian.value)],
              }),
              new TableCell({
                children: [createParagraph(data.german.value)],
              }),
            ],
          });
          table.root.push(tableRow);
        });
      }
    };

    createTableData(worterbuch);
    createTableData(phrasen);
    createTableData(fremdworterbuch);

    const doc = new Document({
      creator: 'Fadmir',
      title: 'Vocabulary Document Export',
      styles: {
        default: {
          heading1: {
            run: {
              size: 35,
              bold: true,
              italics: true,
              color: '000000',
            },
            paragraph: {
              spacing: {
                after: 200,
                before: 120,
              },
              run: {
                size: 25,
              },
            },
          },
          heading2: {
            run: {
              size: 25,
              line: 5,
              bold: false,
              color: '000000',
              font: 'Calibri',
            },
            paragraph: {
              run: {
                size: 23,
              },
              spacing: {
                before: 25,
                after: 25,
              },
              font: 'Calibri',
            },
          },
        },
        paragraphStyles: [
          {
            id: 'aside',
            name: 'Aside',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '999999',
              italics: false,
              size: 26,
            },
            paragraph: {
              spacing: {
                line: 276,
              },
            },
          },
          {
            id: 'wellSpaced',
            name: 'Well Spaced',
            basedOn: 'Normal',
            quickFormat: true,
            paragraph: {
              spacing: {
                line: 276,
                before: 20 * 72 * 0.1,
                after: 20 * 72 * 0.05,
              },
            },
          },
        ],
      },
      sections: [
        {
          children: [
            new Paragraph({
              text: vocabularyType,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
            }),
            createHeading(`${word.german.value}`),
            createHeading(`Bosnisch/Kroatisch/Serbisch - Deutsch`, {
              alignment: AlignmentType.CENTER,
            }),
            table,
          ],
        },
      ],
    });
    Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, `${word.german.value}.docx`);
      console.log('Document created successfully');
    });
  };
  generateDocWord();
}
