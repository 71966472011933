import React, { useRef } from "react";
import { FaFileAudio } from "react-icons/fa";
import AudioPlayer from "react-modular-audio-player";
import classes from "./UploadSound.module.scss";

const UploadSound = ({ handleChangeForm, id, form }) => {
  const fileInputRef = useRef();

  let rearrangedPlayer = [
    {
      className: "player",
      innerComponents: [
        {
          type: "play",
        },
      ],
    },
  ];

  const uploadSound = async (e) => {
    handleChangeForm(e, id);
  };

  return (
    <div style={{ display: "flex" }}>
      <input
        style={{ display: "none" }}
        type="file"
        name="bosnian"
        ref={fileInputRef}
        onChange={(e) => {
          uploadSound(e);
        }}
      />
      <label
        onClick={() => fileInputRef.current.click()}
        className={classes.uploadFile}
      >
        <FaFileAudio size={30} color={"#00a69c"} />
      </label>

      {/* <audio
        controls
        src={base64Audio ? base64Audio : null}
        type="audio/mpeg"
      /> */}
      {form.bosnian.audio && form.bosnian.audio !== "" ? (
        <AudioPlayer
          audioFiles={[
            {
              src: form.bosnian.audio,
            },
          ]}
          rearrange={rearrangedPlayer}
          playerWidth="10rem"
          iconSize="9rem"
          id={id}
        />
      ) : null}
    </div>
  );
};

export default UploadSound;
