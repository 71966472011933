import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import classes from './Search.module.scss';
import debounce from 'lodash.debounce';
import globalContext from '../../context/contextus/globalContext';
import { BiSearchAlt } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

const Search = ({ limit }) => {
  const { searchTerm, dispatch, searchWords, searchResult, language } =
    useContext(globalContext);

  const [searchValue, setSearchValue] = useState(searchTerm);

  const [message, setMessage] = useState('');

  const [placeholderText, setPlaceholderText] = useState(
    'Suche zum Beispiel nach "Fluss"'
  );

  const inputRef = useRef();

  limit = 5;

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    debounce(() => {
      if (inputRef.current.value === searchValue) {
        searchWords(searchValue, limit);
        dispatch({ type: 'SEARCH_QUERY', payload: searchValue });
      }
    }, 700),
    [searchValue, dispatch]
  );

  useEffect(() => {
    if (language === 'german') {
      setPlaceholderText('Suche zum Beispiel nach "fluss"');
    }
    if (language === 'english') {
      setPlaceholderText('Search a words for example "upstream"');
    }
    if (language === 'bosnian') {
      setPlaceholderText('Pretraži riječi na primjer "rijeke"');
    }
  }, [language]);

  useEffect(() => {
    if (searchValue?.length > 2) {
      debouncedFilter();
    } else
      dispatch({
        type: 'SEARCH_WORDS',
        payload: null,
      });
  }, [searchValue, dispatch, debouncedFilter]);

  useEffect(() => {
    setSearchValue(searchTerm);
    inputRef.current.focus();
  }, [searchTerm]);

  const onSearchHandeler = (e) => {
    e.preventDefault(e);

    if (!searchValue) {
      setMessage('Bitte geben Sie ein Wort ein');
      setTimeout(() => {
        setMessage('');
      }, 2000);
    }
    if (searchValue?.length !== '' && searchResult?.length > 0) {
      history.push('/results?' + searchValue);
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={(e) => onSearchHandeler(e)}>
        <input
          className={classes['form-control']}
          placeholder={placeholderText}
          type='text'
          ref={inputRef}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <button type='submit'>
          <BiSearchAlt />
        </button>
      </form>
      {message && <p style={{ marginTop: '10px' }}>{message}</p>}
    </>
  );
};

export default Search;
