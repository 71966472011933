import { saveAs } from 'file-saver';
import {
  Packer,
  Paragraph,
  Document,
  AlignmentType,
  HeadingLevel,
  TableRow,
  TableCell,
  Table,
  WidthType,
} from 'docx';

export function createMultipleWordDoc(words, selects) {
  words?.map((data) => console.log(data.phrasen));

  const createHeading = (text) => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
    });
  };

  const createParagraph = (text, style) => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: style,
    });
  };

  const generateDocWord = () => {
    const table = new Table({
      width: {
        size: 9000,
        type: WidthType.DXA,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                createParagraph('Bosnisch/Kroatisch/Serbisch', 'aside'),
              ],
            }),
            new TableCell({
              children: [createParagraph('Deutsch', 'aside')],
            }),
          ],
        }),
      ],
    });

    const createTableData = (wordData, style) => {
      if (wordData.length > 0) {
        wordData.forEach((data) => {
          let tableRow = new TableRow({
            children: [
              new TableCell({
                children: [createParagraph(data.bosnian.value, style)],
              }),
              new TableCell({
                children: [createParagraph(data.german.value, style)],
              }),
            ],
          });
          table.root.push(tableRow);
        });
      }
    };

    words?.map((data) => {
      createTableData(data.worterbuch, 'aside');
      createTableData(data.phrasen, 'Normal');
      createTableData(data.fremdworterbuch, 'Normal');
    });

    const doc = new Document({
      creator: 'Fadmir',
      title: 'Vocabulary Document Export',
      styles: {
        default: {
          heading1: {
            run: {
              size: 35,
              bold: true,
              italics: true,
              color: '000000',
            },
            paragraph: {
              spacing: {
                after: 300,
                before: 200,
              },
              run: {
                size: 25,
              },
            },
          },
          heading2: {
            run: {
              size: 25,
              line: 5,
              bold: false,
              color: '000000',
              font: 'Calibri',
            },
            paragraph: {
              run: {
                size: 23,
              },
              spacing: {
                before: 25,
                after: 25,
              },
              font: 'Calibri',
            },
          },
        },
        paragraphStyles: [
          {
            id: 'aside',
            name: 'Aside',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '000000',
              italics: false,
              size: 25,
              bold: true,
              font: 'Calibri',
            },
            paragraph: {
              spacing: {
                line: 276,
              },
            },
          },
        ],
      },
      sections: [
        {
          children: [
            new Paragraph({
              text: selects,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
            }),
            createHeading(`Bosnisch/Kroatisch/Serbisch - Deutsch`, {
              alignment: AlignmentType.CENTER,
            }),
            table,
          ],
        },
      ],
    });
    /*  doc.sections.children.push(table2); */

    Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, `${selects}.docx`);
      console.log('Document created successfully');
    });
  };
  generateDocWord();
}
